import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FaqDeatsils, SubscribePlan } from '../interface/pricing';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  private url: string = `${environment.baseURL}`;

  constructor(private http:HttpClient) { }

  subscriptionPlans(params?:any):Observable<SubscribePlan[]>{
    let urlSearchParams = new HttpParams();
    urlSearchParams = params;
    return this.http.get<SubscribePlan[]>(`${this.url}pricing/subscription_plans`,{ params: urlSearchParams })
  }

  faqDetails():Observable<FaqDeatsils[]>{
    return this.http.get<FaqDeatsils[]>(`${this.url}setting/faq/`);
  }
}
